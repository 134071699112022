<template>
	<main class="main-page">
		<template v-if="showHeader">
			<section class="page-section mb-3">
				<div class="container">
					<div class="grid align-items-center">
						<div v-if="!isSubPage" class="col-fixed ">
							<Button @click="$router.go(-1)" label="" className="p-button p-button-text "
								icon="pi pi-arrow-left" />
						</div>
						<div class="col ">
							<div class="">
								<div class="flex align-items-center ">
									<div class="mr-3">
										<Avatar size="large" class="" icon="pi pi-calendar" />
									</div>
									<div>
										<div class="text-2xl text-primary font-bold">Edit Booking</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section class="page-section ">
			<div class="container">
				<div class="grid ">
					<div class="md:col-9 sm:col-12 comp-grid">
						<div>
							<template v-if="pageReady">
								<form ref="observer" tag="form" @submit.prevent="submitForm()"
									:class="{ 'card': !isSubPage }" class="">
									<!--[form-content-start]-->
									<div class="grid">
										<div class="col-12">
											<div class="mb-2 font-bold">Booking</div>
											<div id="ctrl-booking-holder">
												<InputText ref="ctrlbooking" v-model.trim="formData.booking"
													label="Booking" type="text" placeholder="Enter Booking" disabled
													class=" w-full" :class="getErrorClass('booking')">
												</InputText>
												<small v-if="isFieldValid('booking')" class="p-error">{{
													getFieldError('booking') }}</small>
											</div>
										</div>
										<div class="col-12">
											<div class="mb-2 font-bold">Booking Status *</div>
											<div id="ctrl-booking_status-holder">
												<div class="field-radiobutton"
													v-for="option of app.menus.bookingStatusItems" :key="option.value">
													<RadioButton :class="getErrorClass('booking_status')"
														:id="option.value" name="ctrlbooking_status"
														:value="option.value" v-model="formData.booking_status" />
													<label :for="option.value">{{option.label}} </label>
												</div>
												<small v-if="isFieldValid('booking_status')" class="p-error">{{
													getFieldError('booking_status') }}</small>
											</div>
										</div>
										<div class="col-12">
											<div class="mb-2 font-bold">Booking Comments</div>
											<div id="ctrl-booking_comments-holder">
												<Textarea :class="getErrorClass('booking_comments')" class="w-full"
													ref="ctrlbooking_comments" rows="3"
													v-model="formData.booking_comments"
													placeholder="Type your comments here ..." type="textarea">
                                                </Textarea>
												<small v-if="isFieldValid('booking_comments')" class="p-error">{{
													getFieldError('booking_comments') }}</small>
											</div>
										</div>
									</div>
									<!--[form-content-end]-->
									<div v-if="showSubmitButton" class="text-center my-3">
										<Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
									</div>
								</form>
							</template>
							<template v-if="loading">
								<div class="p-3 text-center">
									<ProgressSpinner style="width:50px;height:50px" />
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script setup>
	import { computed, reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useEditPage } from '@/composables/editpage.js';
	const props = defineProps({
		id: [String, Number],
		pageName: {
			type: String,
			default: 'users',
		},
		routeName: {
			type: String,
			default: 'usersbookingedit',
		},
		pagePath: {
			type: String,
			default: 'users/bookingedit',
		},
		apiPath: {
			type: String,
			default: 'users/bookingedit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: 'Update Record',
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		msgAfterSave: {
			type: String,
			default: "Record updated successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
	});
	const app = useApp();
	const formDefaultValues = Object.assign({
		booking: "",
		booking_status: "",
		booking_comments: "",
	}, props.pageData);
	const formData = reactive({ ...formDefaultValues });
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if (app.isDialogOpen()) {
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if (props.redirect) {
			app.navigateTo(`/`);
		}
	}
	const rules = computed(() => {
		return {
			booking: {},
			booking_status: { required },
			booking_comments: {}
		}
	});
	const v$ = useVuelidate(rules, formData); //form validation
	const page = useEditPage({ props, formData, v$, afterSubmit });
	const { submitted, saving, loading, pageReady } = toRefs(page.state);
	const { apiUrl, currentRecord } = page.computedProps;
	const { load, submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
	onMounted(() => {
		const pageTitle = "Edit Booking";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>